body{
    font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.primary-color{
    background-color: #1887C9;
}

.primary-bg-color{
    background-color: #11608F;
}
.secondary-bg-color{
    background-color: #B7DAEE;
}
.primary-button{
  background-color: #1887C9;
    padding: 10px 20px;
    width: 130px;
    border-radius: 8px;
}
.apply-button{
  background-color: #469FD4;
  padding: 15px 30px;
  width: 200px;
  border-radius: 8px;
}
.apply-button-mini{
  background-color: #469FD4;
  padding: 15px 30px;
  width: 150px;
  border-radius: 8px;
}
.line-height-2{
    line-height: 2;
}
.basic-black{
  color: #001833;
}
.section-bg-color{
  background-color: #F9F9F9;
}
.basic-btn{
  width: 180px;
  height: 52px;
}
@media (max-width: 768px) {
    .container {
      overflow-x: hidden; 
      font-size: 16px;
     
    }
  }
  
  @media (max-width: 400px) {
    .container {
      overflow-x: hidden; 
      font-size: 8px;
     
    }
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .outlined-text {
    color: transparent; /* Makes the text fill transparent */
    -webkit-text-stroke: 1px #1887C9; /* Sets the outline color and thickness */
  }